import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Chennai from "../images/logo/chennai.jpg";
import bangalore from "../images/logo/bangalore.jpg";
import coimbatore from "../images/logo/coimbatore.jpg";
import kanyakumari from "../images/logo/kanyakumari.jpg";
import kodaikannal from "../images/logo/kodaikannal.jpg";
import thanjavur from "../images/logo/thanjavur.jpg";
import tirupathi from "../images/logo/tirupathi.jpg";
import trichy from "../images/logo/trichy.jpg";
import ooty from "../images/logo/ooty.jpg";

import { Link } from "react-router-dom";

function Gallery({setActivePage}) {
  const locations = [
    {
      name: "Chennai",
      imgSrc: Chennai,
    },
    {
      name: "Bangalore",
      imgSrc: bangalore,
    },
    {
      name: "coimbatore",
      imgSrc: coimbatore,
    },
    {
      name: "Kanyakumari",
      imgSrc: kanyakumari,
    },
    {
      name: "kodaikannal",
      imgSrc: kodaikannal,
    },
    {
      name: "thanjavur",
      imgSrc: thanjavur,
    },
    {
      name: "tirupathi",
      imgSrc: tirupathi,
    },
    {
      name: "trichy",
      imgSrc: trichy,
    },
    {
      name: "ooty",
      imgSrc: ooty,
    },
  ];
  return (
    <>
      <section className="models-section">
        <HeroPages name="Gallary" />
        <div className="container">
          <div className="models-div">
            {locations.map((location, index) => (
              <div className="models-div__box" key={index}>
                <div className="models-div__box__img">
                  <img src={location.imgSrc} alt={`${location.name}_img`} />
                  <div className="models-div__box__descr">
                    <div className="models-div__box__descr__name-price">
                      <div className="models-div__box__descr__name-price__name">
                        <p>{location.name}</p>
                      </div>
                    </div>
                    <div className="models-div__box__descr__name-price__btn">
                      <a onClick={() => {
    setActivePage("home");

  }} >
                        Book Ride
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(+91) 91767-27208</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Gallery;
