import React, { useState } from "react";
import "../src/dist/styles.css";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Navbar from "../src/components/Navbar";
import Models from "./Pages/Models";
import TestimonialsPage from "./Pages/TestimonialsPage";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";

function App() {
  const [activePage, setActivePage] = useState("home");

  const renderPage = () => {
    switch (activePage) {
      case "home":
        return <Home />;
      case "about":
        return <About />;
      case "models":
        return <Models />;
      case "gallery":
        return <Gallery setActivePage={setActivePage}/>;
      case "testimonials":
        return <TestimonialsPage />;
      case "team":
        return <Team />;
      case "contact":
        return <Contact />;
      default:
        return <Home />;
    }
  };

  return (
    <>
      <Navbar setActivePage={setActivePage} />
      {renderPage()}
    </>
  );
}

export default App;
