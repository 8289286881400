function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>Taxi</span>ma
              </li>
              <li>
                We offers a big range of vehicles for all your Taxi needs. We
                have the perfect car to meet your needs.
              </li>
              <li>
                <a href="tel:9176727208">
                  <i className="fa-solid fa-phone"></i> &nbsp; (+91) 91767-27208
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                taximaofficial@gmail.com"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; taximaofficial@gmail.com
                </a>
              </li>

              {/* <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="#"
                >
                  Design with ❤️ by 
                </a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Address</li>

              <li>
                No 599, raghavendra nagar,reddypalayam road, Thanjavur 613009
              </li>
              {/* <li>
                <a href="#home">Careers</a>
              </li>
              <li>
                <a href="#home">Mobile</a>
              </li>
              <li>
                <a href="#home">Blog</a>
              </li>
              <li>
                <a href="#home">How we work</a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>24x7 Cab Service</li>
              <li>All Over South India.</li>
              {/* <li>Mon - Fri: 9:00AM - 9:00PM</li>
              <li>Sat: 9:00AM - 19:00PM</li>
              <li>Sun: Closed</li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Privacy Policies</li>
              <li>
                <p>
                  Kindly bring any Government Issued Identity Card before the
                  trip begins. It will be verified by the driver during your
                  trip.
                </p>
              </li>
              {/* <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li> */}
            </ul>
            <p>&copy; 2024 Taxima. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
