import { useState } from "react";

function CarBox({ data, carID }) {
  const [carLoad, setCarLoad] = useState(true);
  return (
    <>
      {data[carID].map((car, id) => (
        <div key={id} className="box-cars">
          {/* car */}
          <div className="pick-car">
            {carLoad && <span className="loader"></span>}
            <img
              style={{ display: carLoad ? "none" : "block" }}
              src={car.img}
              alt="car_img"
              onLoad={() => setCarLoad(false)}
            />
          </div>
          {/* description */}
          <div className="pick-description">
            <div className="pick-description__price">
              <span>₹{car.price}</span>/ per KM
            </div>
            <div className="pick-description__table">
              <div className="pick-description__table__col">
                <span>ONE-WAY TRIP</span>
                <span>{car.oneWay}</span>
              </div>

              <div className="pick-description__table__col">
                <span>ROUND TRIP</span>
                <span>{car.round}</span>
              </div>

              <div className="pick-description__table__col">
                <span>DRIVER BETTA</span>
                <span>{car.driverbetta}</span>
              </div>

              <div className="pick-description__table__col">
                <span>EXTRA</span>
                <span>{car.extra}</span>
              </div>
            </div>
            {/* btn cta */}
            <a className="cta-btn" href="#booking-section">
              Reserve Now
            </a>
          </div>
        </div>
      ))}
    </>
  );
}

export default CarBox;
