import Suv from "../images/cars-big/SUV.jpg";
import Sedan from "../images/cars-big/sedan.jpg";
import SuvInnova from "../images/cars-big/innova.jpg";

export const CAR_DATA = [
  [
    {
      name: "Suv ",
      price: "19",
      img: Suv,
      oneWay: "19/KM",
      round: "18/KM",
      driverbetta: "500",
      extra: "Toll,Parking,State Permit & Hill Station",
    },
  ],
  [
    {
      name: "Sedan ",
      price: "14",
      img: Sedan,
      oneWay: "14/KM",
      round: "13/KM",
      driverbetta: "400",
      extra: "Toll,Parking,State Permit & Hill Station",
    },
  ],
  [
    {
      name: "Innova",
      price: "20",
      img: SuvInnova,
      oneWay: "20/KM",
      round: "19/KM",
      driverbetta: "500",
      extra: "Toll,Parking,State Permit & Hill Station",
    },
  ],
  [
    {
      name: "crista",
      price: "25",
      img: SuvInnova,
      oneWay: "25/KM",
      round: "23/KM",
      driverbetta: "600",
      extra: "Toll,Parking,State Permit & Hill Station",
    },
  ],
];
