import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import whatsapp from "../images/logo/whatsapp-symbol.svg";
import telephoneIcon from "../images/logo/telephone-icon.svg";

function Home() {
  return (
    <>
      <Hero />
      <BookCar />
      <PickCar />
      <PlanTrip />

      <Banner />
      <ChooseUs />
      <Testimonials />
      {/* <Faq /> */}

      <Footer />
      <div className="fixed-tel">
        <a href="tel:9176727208">
          <img
            src={telephoneIcon}
            height={40}
            width={40}
            style={{ marginLeft: "2px" }}
            alt="whatsapplogo"
          />
        </a>
      </div>
      <div className="whazzup">
        <a
          target="_blank"
          href="https://wa.me/919176727208?text=I%27m%20looking%20for%20an%20outstation%20taxi"
        >
          <img
            src={whatsapp}
            height={40}
            width={40}
            style={{ marginLeft: "2px" }}
            alt="whatsapplogo"
          />
        </a>
      </div>
    </>
  );
}

export default Home;
