import { Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import { useState } from "react";

function Navbar({ setActivePage }) {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          <ul className="mobile-navbar__links">
            <li>
            <a 
  onClick={() => {
    setActivePage("home");
    openNav();
  }}
>
  Home
</a>
            </li>
            <li>
            <a 
  onClick={() => {
    setActivePage("about");
    openNav();
  }}
>
About
</a>
            </li>
            {/* <li>
              <a onClick={openNav} to="/models">
                Models
              </a>
            </li> */}
            <li>
              <a  onClick={() => {
    setActivePage("gallery");
    openNav();
  }} >
                Gallary
              </a>
            </li>
            <li>
              <a onClick={() => {
    setActivePage("testimonials");
    openNav();
  }} >
                Testimonials
              </a>
            </li>
            {/* <li>
              <a onClick={openNav} to="/team">
                Our Team
              </a>
            </li> */}
            <li>
              <a onClick={() => {
    setActivePage("contact");
    openNav();
  }} >
                Contact
              </a>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <a to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" className="logo" />
            </a>
          </div>
          <ul className="navbar__links1">
            <li>
              <a href="tel:9176727208">
                <i className="fa-solid fa-phone"></i> &nbsp; (+91) 91767-27208
              </a>
            </li>
          </ul>
          <ul className="navbar__links">
            <li>
              <a className="home-link"  onClick={() => {
    setActivePage("home");

  }}>
                Home
              </a>
            </li>
            <li>
              {" "}
              <a className="about-link"   onClick={() => {
    setActivePage("about");
    // openNav();
  }}>
                About
              </a>
            </li>
            {/* <li>
              {" "}
              <a className="models-link" to="/models">
                Vehicle Models
              </a>
            </li> */}
            <li>
              {" "}
              <a className="models-link"  onClick={() => {
    setActivePage("gallery");

  }}>
                Gallary
              </a>
            </li>
            <li>
              {" "}
              <a className="testi-link" onClick={() => {
    setActivePage("testimonials");

  }}>
                Testimonials
              </a>
            </li>
            {/* <li>
              {" "}
              <a className="team-link" to="/team">
                Our Team
              </a>
            </li> */}
            <li>
              {" "}
              <a className="contact-link" onClick={() => {
    setActivePage("contact");

  }} >
                Contact
              </a>
            </li>
          </ul>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
