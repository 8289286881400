import { useEffect, useState } from "react";
import Sedan from "../images/cars-big/sedan.jpg";
import Suv from "../images/cars-big/SUV.jpg";
import SuvInnova from "../images/cars-big/innova.jpg";
import styled from "styled-components";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
import { Space, TimePicker, DatePicker } from "antd";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Box from '@mui/material/Box';
import { TextField, InputAdornment } from "@mui/material";
function BookCar() {
  const [modal, setModal] = useState(false); //  class - active-modal

  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [date, setDate] = useState("");
  const [carImg, setCarImg] = useState("");
  const [selectedValue, setSelectedValue] = useState("one way trip");
  const [selectedCarValue, setSelectedCarValue] = useState("Sedan");
  const [image, setImage] = useState(Sedan);
  const [noOfDays, setNoOfDays] = useState("");
  const [distance, setDistance] = useState("");
  const [driver,setDriver]=useState(400)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inputValue, setInputValue] = useState("");
  // modal infos

  const [amount, setAmount] = useState(14);
  const [estAmount, setEstAmount] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");


  // taking value of modal inputs
  const dateFormatList = [
    "YYYY-MM-DD",
    "YYYY-MM-DD",
    "YYYY-MM-DD",
    "YYYY-MM-DD",
  ];

  const handleCarType = (value) => {
    setSelectedCarValue(value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleDays = (e) => {
    setNoOfDays(e.target.value);
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };
  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (pickUp === "" || dropOff === "" || pickTime === "" || date === "") {
      errorMsg.style.display = "flex";
    } else {
      calculateDrivingDistance();
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = (e) => {
    e.preventDefault();
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
   
    const data = {
      trip: selectedValue,
      car_type: selectedCarValue,
      user_name: name,
      mobile_no: phone,
      email_id: email,
      pickup_loc: pickUp.formatted_address,
      drop_loc: dropOff.formatted_address,
      pick_date: date,
      pick_time: pickTime,
      status: 1,
      estimated_amt: Number(estAmount),
      km_rate: Number(amount),
      days:(selectedValue === "round trip") ? Number(noOfDays) : null,
    };

    axios
      .post("https://taxima.in/php/booking.php", data)
      .then((response) => {
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message,
        );
      });
  };

  const handlePickTime = (time, timeString) => {
    setPickTime(timeString);
  };

  const handleDate = (date, dateString) => {
    setDate(dateString);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Sedan":
      imgUrl = Sedan;
      break;
    case "Suv":
      imgUrl = Suv;
      break;
    case "SuvInnova":
      imgUrl = SuvInnova;
      break;
  }

  const cardStyle = {
    width: "55rem",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  };

  const cardImage = {
    display: "flex",
    justifyContent: "center",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
  };

  const bodyStyle = {
    padding: "16px",
  };

  const titleStyle = {
    fontSize: "2rem",
    margin: "0",
    textTransform: "uppercase",
  };

  const textStyle = {
    color: "#555",
    fontSize: "1.5rem",
  };

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };


  const googleMapsApiKey = "AIzaSyBEyJ-mkUxgcg7ocVZ2G6ADOSRUAauwnvw"; // Replace with your API key
  let estimated_amt = 0
  // const km = Number(distance.slice(0, -2).replace(/,/g, ""));
  const calculateDrivingDistance = (e) => { 
    console.log(pickUp, "pickUp");
    console.log(dropOff, "dropOff");
  
    if (pickUp && dropOff) {
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [{ placeId: pickUp.place_id }],
          destinations: [{ placeId: dropOff.place_id }],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK" && response.rows[0].elements[0].status === "OK") {
            console.log(response, "response");
            
            // Get the distance in meters and convert to kilometers
            const distanceMeters = response.rows[0].elements[0].distance.value;
            const distanceKm = distanceMeters / 1000;
  
            setDistance(Math.round(distanceKm));
  
            let estimated_amt;
  
            // Adjust calculations based on the distance in kilometers
            if (distanceKm <= 130 && selectedValue === "one way trip") {
              estimated_amt = Math.round(130 * amount) + driver;
            } else if (distanceKm <= 250 && selectedValue === "round trip") {
              estimated_amt = Math.round(250 * amount) + driver;
            } else {
              estimated_amt = Math.round(distanceKm * amount) + driver;
            }
  
            setEstAmount(estimated_amt);
          } else {
            setDistance("Unable to calculate the driving distance.");
          }
        }
      );
    } else {
      setDistance("Please select both pickup and drop locations.");
    }
  };
  


  function formatPrice(amount, currency = "INR") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  const handleDateTimeChange = (newValue) => {
    const formattedDate = newValue.format('YYYY-MM-DD');
    const formattedTime = newValue.format('hh:mm A');
    const displayFormat = newValue.format('DD MMM YYYY hh:mm A');

    setDate(formattedDate);
    setPickTime(formattedTime);
    // setDisplayDateTime(displayFormat);
  };
  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Book a car</h2>
              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>
              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>

              <StyledWrapper>
                <div className="radio-div">
                  <div className="radio-input">
                    <label>
                      <input
                        value="one way trip"
                        name="trip"
                        id="trip-value-1"
                        type="radio"
                        checked={
                          selectedValue === "" ||
                          selectedValue === "one way trip"
                        }
                        onChange={() => handleRadioChange("one way trip")}
                        onClick={() => {
                          if (selectedCarValue === "Sedan") {
                            setAmount(14);
                          } else if (selectedCarValue === "suv") {
                            setAmount(19);
                          } else {
                            setAmount(20);
                          }
                        }}
                      />
                      <span>ONE-WAY TRIP</span>
                    </label>
                    <label>
                      <input
                        value="round trip"
                        name="trip"
                        id="trip-value-2"
                        type="radio"
                        checked={selectedValue === "round trip"}
                        onChange={() => handleRadioChange("round trip")}
                        onClick={() => {
                          if (selectedCarValue === "Sedan") {
                            setAmount(13);
                          } else if (selectedCarValue === "suv") {
                            setAmount(18);
                          } else {
                            setAmount(19);
                          }
                        }}
                      />
                      <span>ROUND TRIP</span>
                    </label>
                    <span className="selection" />
                  </div>
                </div>
              </StyledWrapper>
              <StyledWrapper2>
                <div className="car">
                  <div className="container">
                    <div className="radio-tile-group">
                      <div className="input-container">
                        <input
                          id="car-1"
                          className="radio-button"
                          type="radio"
                          name="car-1"
                          value="Sedan"
                          checked={
                            selectedCarValue === "Sedan" ||
                            selectedCarValue === ""
                          }
                          onChange={() => handleCarType("Sedan")}
                          onClick={() => {
                            if (selectedValue === "one way trip") {
                              setAmount(14);
                            } else {
                              setAmount(13);
                            }
                            setImage(Sedan);
                            setDriver(400)
                            setDriver(400)
                          }}
                        />
                        <div className="radio-tile">
                          <div className="icon walk-icon">
                            <svg
                              fill="#000000"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="800px"
                              height="800px"
                              viewBox="0 0 98.967 98.967"
                            >
                              <path
                                d="M17.275,52.156c-4.124,0-7.468,3.343-7.468,7.468c0,0.318,0.026,0.631,0.066,0.938c0.463,3.681,3.596,6.528,7.401,6.528
			c3.908,0,7.112-3.004,7.437-6.83c0.017-0.209,0.031-0.422,0.031-0.637C24.743,55.499,21.4,52.156,17.275,52.156z M13.537,56.81
			l1.522,1.523c-0.118,0.203-0.211,0.422-0.271,0.656h-2.146C12.752,58.177,13.063,57.435,13.537,56.81z M12.632,60.282h2.163
			c0.061,0.23,0.151,0.448,0.271,0.648l-1.526,1.525C13.067,61.835,12.749,61.093,12.632,60.282z M16.629,64.263
			c-0.809-0.113-1.544-0.43-2.166-0.899l1.518-1.519c0.2,0.117,0.419,0.203,0.648,0.263V64.263z M16.629,57.14
			c-0.235,0.062-0.455,0.154-0.66,0.275l-1.521-1.521c0.625-0.475,1.367-0.789,2.181-0.902V57.14z M17.922,54.99
			c0.814,0.113,1.557,0.429,2.181,0.903l-1.52,1.521c-0.204-0.121-0.426-0.213-0.66-0.275L17.922,54.99L17.922,54.99z
			 M17.922,64.261v-2.152c0.23-0.061,0.447-0.146,0.647-0.264l1.519,1.519C19.466,63.833,18.73,64.148,17.922,64.261z
			 M21.014,62.462l-1.531-1.533c0.12-0.201,0.217-0.416,0.278-0.646h2.146C21.793,61.091,21.488,61.839,21.014,62.462z
			 M19.764,58.989c-0.061-0.234-0.153-0.453-0.271-0.656l1.524-1.523c0.471,0.625,0.782,1.367,0.894,2.18H19.764z"
                              />
                              <path
                                d="M79.284,52.156c-4.124,0-7.468,3.343-7.468,7.468c0,0.318,0.026,0.631,0.066,0.938c0.463,3.681,3.596,6.528,7.4,6.528
			c3.908,0,7.112-3.004,7.438-6.83c0.017-0.209,0.031-0.422,0.031-0.637C86.753,55.499,83.409,52.156,79.284,52.156z M75.546,56.81
			l1.521,1.523c-0.118,0.203-0.211,0.422-0.271,0.656H74.65C74.761,58.177,75.072,57.435,75.546,56.81z M74.642,60.282h2.163
			c0.061,0.23,0.151,0.448,0.271,0.648l-1.525,1.525C75.076,61.835,74.757,61.093,74.642,60.282z M78.638,64.263
			c-0.809-0.113-1.544-0.43-2.166-0.899l1.518-1.519c0.2,0.117,0.419,0.203,0.648,0.263V64.263z M78.638,57.14
			c-0.235,0.062-0.455,0.154-0.66,0.275l-1.521-1.521c0.625-0.475,1.366-0.789,2.181-0.902V57.14z M79.932,54.99
			c0.814,0.113,1.557,0.429,2.181,0.903l-1.521,1.521c-0.204-0.121-0.426-0.215-0.66-0.275V54.99z M79.932,64.261v-2.152
			c0.23-0.061,0.447-0.146,0.647-0.264l1.519,1.519C81.476,63.833,80.739,64.148,79.932,64.261z M83.023,62.462l-1.531-1.531
			c0.12-0.202,0.218-0.416,0.278-0.647h2.146C83.802,61.091,83.498,61.839,83.023,62.462z M81.773,58.989
			c-0.061-0.234-0.152-0.453-0.271-0.656l1.523-1.523c0.472,0.625,0.782,1.367,0.895,2.18H81.773z"
                              />
                              <path
                                d="M97.216,48.29v-5.526c0-0.889-0.646-1.642-1.524-1.779c-2.107-0.33-5.842-0.953-7.52-1.47
			c-2.406-0.742-11.702-4.678-14.921-5.417c-3.22-0.739-17.738-4.685-31.643,0.135c-2.353,0.815-12.938,5.875-19.162,8.506
			c-1.833,0.04-19.976,3.822-20.942,6.414c-0.966,2.593-1.269,3.851-1.447,4.509c-0.178,0.658,0,3.807,1.348,6
			c1.374,0.777,4.019,1.299,7.077,1.649c-0.035-0.187-0.073-0.371-0.097-0.56c-0.053-0.404-0.078-0.773-0.078-1.125
			c0-4.945,4.022-8.969,8.968-8.969s8.968,4.023,8.968,8.969c0,0.254-0.017,0.506-0.036,0.754c-0.047,0.555-0.147,1.094-0.292,1.613
			c0.007,0,0.024,0,0.024,0l44.516-0.896c-0.02-0.115-0.046-0.229-0.061-0.346c-0.053-0.402-0.078-0.772-0.078-1.125
			c0-4.945,4.022-8.968,8.968-8.968c4.946,0,8.969,4.022,8.969,8.968c0,0.019-0.002,0.035-0.003,0.053l0.19-0.016l7.611-1.433
			c0,0,2.915-1.552,2.915-5.822C98.967,49.56,97.216,48.29,97.216,48.29z M53.057,43.051L36.432,43.56
			c0.306-2.491-1.169-3.05-1.169-3.05c6.609-5.999,19.929-6.202,19.929-6.202L53.057,43.051z M71.715,42.29l-15.15,0.509l1.373-8.49
			c7.83-0.102,12.303,1.626,12.303,1.626l2.237,3.61L71.715,42.29z M80.256,42.238h-4.221l-4.22-5.795
			c3.166,1.26,5.7,2.502,7.209,3.287C79.94,40.206,80.44,41.223,80.256,42.238z"
                              />
                            </svg>
                          </div>
                          <label htmlFor="walk" className="radio-tile-label">
                            SEDAN
                          </label>
                        </div>
                      </div>

                      <div className="input-container">
                        <input
                          id="car-2"
                          className="radio-button"
                          type="radio"
                          name="car-2"
                          value="suv"
                          checked={selectedCarValue === "suv"}
                          onChange={() => handleCarType("suv")}
                          onClick={() => {
                            if (selectedValue === "one way trip") {
                              setAmount(19);
                            } else {
                              setAmount(18);
                            }
                            setImage(Suv);
                            setDriver(500)
                            setDriver(500)
                          }}
                        />
                        <div className="radio-tile">
                          <div className="icon bike-icon">
                            <svg
                              fill="#000000"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="800px"
                              height="800px"
                              viewBox="0 0 99.488 99.488"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M98.587,52.231l-0.68-0.106c0.244-9.324-1.22-11.202-2.858-16.912h0.901c0.428,0,0.773-0.347,0.773-0.774V33.55
			c0-0.427-0.347-0.773-0.773-0.773H41.123c-0.805,0-1.594,0.216-2.286,0.625L22.22,43.218c0,0-16.635,0.671-19.213,3.222
			c-1.303,1.289-2.481,3.832-2.911,6.767c-0.43,2.934,0.693,6.062,1.331,6.737c0.639,0.675,1.663,0.998,1.663,0.998l0.406,0.771
			c0.262,0.496,0.78,0.801,1.341,0.787l2.674-0.064v-7.383c0-2.863,2.324-5.188,5.189-5.188h7.099c2.511,0,4.548,2.035,4.548,4.549
			v7.612l0.121-0.003l43.416-0.667l0.625,0.063v-6.367c0-2.863,2.324-5.188,5.188-5.188h7.101c2.512,0,4.547,2.035,4.547,4.549
			v8.323l5.991-1.272c0.315-0.067,0.585-0.276,0.729-0.566l0.438-0.872l6.059-0.793c0.53-0.069,0.927-0.522,0.927-1.058v-4.893
			C99.487,52.76,99.105,52.313,98.587,52.231z M46.435,43.697c-0.133,0.142-0.319,0.223-0.515,0.223H32.695
			c0,0,0.812-0.417,1.237-1.012c0.426-0.593,0.19-1.998-0.288-2.472c-0.439-0.434-1.322-0.729-1.322-0.729l6.651-4.095
			c0.426-0.263,0.916-0.401,1.417-0.401h5.628c0.188,0,0.368,0.075,0.5,0.209c0.132,0.134,0.205,0.314,0.202,0.503l-0.097,7.244
			C46.636,43.363,46.569,43.554,46.435,43.697z M63.045,43.109c-0.444,0.539-1.103,0.857-1.803,0.873l-10.585,0.23
			c-0.189,0.004-0.373-0.067-0.508-0.199c-0.136-0.133-0.212-0.315-0.212-0.504v-7.595c0-0.388,0.314-0.702,0.702-0.702h11.748
			c0.364,0,0.667,0.277,0.699,0.64l0.474,5.323C63.677,41.864,63.489,42.571,63.045,43.109z M94.358,43.115
			c-0.062,0.105-0.175,0.168-0.297,0.169l-21.502,0.162c-0.931,0.007-1.799-0.468-2.298-1.255l-2.254-6.031
			c-0.081-0.216-0.051-0.457,0.08-0.646c0.132-0.189,0.348-0.302,0.577-0.302h22.746c0.306,0,0.574,0.196,0.669,0.486l2.288,7.074
			C94.425,42.881,94.421,43.011,94.358,43.115z"
                                  />
                                  <path
                                    d="M15.926,51.364c-4.235,0-7.673,3.433-7.673,7.672c0,0.329,0.027,0.647,0.067,0.966c0.477,3.782,3.696,6.709,7.605,6.709
			c4.022,0,7.31-3.086,7.644-7.02c0.019-0.219,0.033-0.434,0.033-0.655C23.603,54.797,20.167,51.364,15.926,51.364z M12.083,56.146
			l1.568,1.563c-0.123,0.213-0.221,0.438-0.279,0.678h-2.207C11.279,57.549,11.599,56.787,12.083,56.146z M11.157,59.715h2.224
			c0.063,0.234,0.154,0.459,0.279,0.666l-1.571,1.567C11.605,61.311,11.275,60.547,11.157,59.715z M15.262,63.803
			c-0.829-0.111-1.586-0.438-2.225-0.92l1.561-1.562c0.203,0.12,0.43,0.207,0.664,0.27V63.803z M15.262,56.485
			c-0.239,0.062-0.466,0.159-0.678,0.282l-1.561-1.563c0.641-0.489,1.403-0.812,2.238-0.929L15.262,56.485L15.262,56.485z
			 M16.59,54.276c0.84,0.116,1.602,0.438,2.244,0.931l-1.563,1.561c-0.209-0.122-0.438-0.221-0.682-0.281L16.59,54.276L16.59,54.276
			z M16.59,63.803V61.59c0.237-0.062,0.462-0.149,0.669-0.271l1.559,1.564C18.18,63.364,17.423,63.69,16.59,63.803z M19.771,61.952
			l-1.572-1.573c0.12-0.205,0.222-0.426,0.284-0.664h2.204C20.571,60.547,20.258,61.316,19.771,61.952z M18.483,58.387
			c-0.058-0.239-0.154-0.465-0.275-0.678l1.564-1.563c0.486,0.644,0.806,1.403,0.92,2.241H18.483z"
                                  />
                                  <path
                                    d="M76.924,51.364c-4.236,0-7.675,3.433-7.675,7.672c0,0.329,0.026,0.647,0.068,0.966c0.476,3.782,3.696,6.709,7.605,6.709
			c4.021,0,7.309-3.086,7.643-7.02c0.02-0.219,0.033-0.434,0.033-0.655C84.6,54.797,81.166,51.364,76.924,51.364z M73.081,56.146
			l1.566,1.563c-0.123,0.213-0.22,0.438-0.278,0.678h-2.206C72.276,57.549,72.596,56.787,73.081,56.146z M72.154,59.715h2.224
			c0.063,0.234,0.153,0.459,0.278,0.666l-1.569,1.567C72.602,61.311,72.272,60.547,72.154,59.715z M76.259,63.803
			c-0.83-0.111-1.587-0.438-2.226-0.92l1.562-1.562c0.203,0.12,0.431,0.207,0.664,0.27V63.803z M76.259,56.485
			c-0.238,0.062-0.466,0.159-0.679,0.282l-1.561-1.563c0.64-0.489,1.402-0.812,2.238-0.929L76.259,56.485L76.259,56.485z
			 M77.589,54.276c0.839,0.116,1.601,0.438,2.243,0.931l-1.563,1.561c-0.209-0.122-0.438-0.221-0.681-0.281L77.589,54.276
			L77.589,54.276z M77.589,63.803V61.59c0.235-0.062,0.461-0.149,0.668-0.271l1.559,1.564C79.177,63.364,78.421,63.69,77.589,63.803
			z M80.769,61.952l-1.573-1.573c0.12-0.205,0.223-0.426,0.285-0.664h2.204C81.569,60.547,81.255,61.316,80.769,61.952z
			 M79.481,58.387c-0.059-0.239-0.155-0.465-0.274-0.678l1.563-1.563c0.484,0.644,0.806,1.403,0.92,2.241H79.481z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <label htmlFor="bike" className="radio-tile-label">
                            SUV
                          </label>
                        </div>
                      </div>

                      <div className="input-container">
                        <input
                          id="car-3"
                          className="radio-button"
                          type="radio"
                          name="car-3"
                          value="innova"
                          checked={selectedCarValue === "innova"}
                          onChange={() => handleCarType("innova")}
                          onClick={() => {
                            if (selectedValue === "one way trip") {
                              setAmount(20);
                            } else {
                              setAmount(19);
                            }

                            setImage(SuvInnova);
                            setDriver(500)
                            setDriver(500)
                          }}
                        />
                        <div className="radio-tile">
                          <div className="icon car-icon">
                            <svg
                              fill="#000000"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="800px"
                              height="800px"
                              viewBox="0 0 99.442 99.443"
                            >
                              <path
                                d="M19.097,54.071c-4.175,0-7.561,3.383-7.561,7.56c0,0.324,0.026,0.641,0.066,0.951c0.469,3.729,3.642,6.611,7.494,6.611
			c3.959,0,7.202-3.042,7.53-6.916c0.018-0.214,0.033-0.428,0.033-0.646C26.66,57.454,23.275,54.071,19.097,54.071z M15.31,58.782
			l1.543,1.543c-0.121,0.206-0.214,0.429-0.274,0.665h-2.174C14.518,60.165,14.833,59.415,15.31,58.782z M14.397,62.298h2.189
			c0.062,0.233,0.153,0.454,0.274,0.656L15.314,64.5C14.838,63.871,14.513,63.119,14.397,62.298z M18.443,66.328
			c-0.818-0.112-1.564-0.434-2.193-0.908l1.537-1.538c0.202,0.118,0.424,0.205,0.656,0.266V66.328z M18.443,59.116
			c-0.238,0.062-0.461,0.157-0.668,0.279l-1.541-1.541c0.633-0.48,1.385-0.8,2.209-0.913V59.116z M19.752,56.941
			c0.826,0.113,1.577,0.433,2.209,0.914l-1.54,1.54c-0.207-0.122-0.43-0.218-0.669-0.279V56.941z M19.752,66.328v-2.182
			c0.233-0.061,0.454-0.147,0.657-0.268l1.538,1.54C21.317,65.894,20.572,66.214,19.752,66.328z M22.885,64.504l-1.551-1.551
			c0.12-0.203,0.22-0.42,0.282-0.655h2.172C23.673,63.119,23.364,63.875,22.885,64.504z M21.617,60.99
			c-0.06-0.236-0.153-0.459-0.274-0.665l1.543-1.543c0.478,0.633,0.792,1.383,0.905,2.208H21.617z"
                              />
                              <path
                                d="M83.965,54.071c-4.176,0-7.561,3.383-7.561,7.56c0,0.324,0.025,0.641,0.065,0.951c0.468,3.729,3.643,6.611,7.494,6.611
			c3.958,0,7.201-3.042,7.53-6.916c0.018-0.214,0.031-0.428,0.031-0.646C91.526,57.454,88.142,54.071,83.965,54.071z M80.177,58.782
			l1.543,1.543c-0.12,0.206-0.214,0.429-0.273,0.665h-2.175C79.385,60.165,79.7,59.415,80.177,58.782z M79.265,62.298h2.19
			c0.062,0.233,0.152,0.454,0.272,0.656L80.182,64.5C79.705,63.871,79.38,63.119,79.265,62.298z M83.31,66.328
			c-0.818-0.112-1.563-0.434-2.192-0.908l1.537-1.538c0.201,0.118,0.424,0.205,0.655,0.266V66.328z M83.31,59.116
			c-0.237,0.062-0.461,0.157-0.669,0.279L81.1,57.854c0.634-0.48,1.385-0.8,2.209-0.913L83.31,59.116L83.31,59.116z M84.62,56.941
			c0.824,0.113,1.576,0.433,2.209,0.914l-1.541,1.54c-0.207-0.122-0.431-0.218-0.668-0.279V56.941z M84.62,66.328v-2.182
			c0.231-0.061,0.454-0.147,0.655-0.268l1.538,1.54C86.184,65.894,85.438,66.214,84.62,66.328z M87.752,64.504l-1.551-1.551
			c0.12-0.203,0.22-0.42,0.281-0.655h2.174C88.54,63.119,88.23,63.875,87.752,64.504z M86.483,60.99
			c-0.06-0.236-0.152-0.459-0.272-0.665l1.542-1.543c0.478,0.633,0.792,1.383,0.906,2.208H86.483z"
                              />
                              <path
                                d="M99.091,47.939c-0.056-1.67-0.516-3.301-1.339-4.754l-5.43-9.581c-0.89-1.569-2.521-2.573-4.322-2.664
			c-9.1-0.456-37.002-1.618-45.786,0.744C36.272,33.283,21.278,43.14,21.278,43.14S4.781,45.695,1.634,53.219
			c0,0-1.358,0.793-1.605,2.826c-0.127,1.046,0.183,2.634,0.525,3.965c0.375,1.456,1.582,2.552,3.067,2.783l7.16,1.122
			c-0.107-0.391-0.196-0.788-0.248-1.198c-0.045-0.354-0.075-0.716-0.075-1.087c0-4.763,3.875-8.637,8.639-8.637
			c4.765,0,8.64,3.874,8.64,8.637c0,0.249-0.016,0.493-0.036,0.735c-0.072,0.844-0.268,1.651-0.567,2.408l0.842,0.045l47.568-1.287
			c-0.061-0.268-0.109-0.538-0.145-0.814c-0.046-0.354-0.074-0.716-0.074-1.087c0-4.763,3.875-8.637,8.638-8.637
			c4.765,0,8.64,3.874,8.64,8.637c0,0.249-0.016,0.493-0.037,0.735c-0.013,0.16-0.041,0.315-0.062,0.473L96.609,62
			c1.693-0.346,2.891-1.86,2.831-3.589L99.091,47.939z M71.715,32.71l1.093,10.911l-16.774,0.686V32.655
			C60.938,32.542,66.536,32.593,71.715,32.71z M29.387,45.087l-1.659,0.093c-0.451,0.025-0.864-0.249-1.016-0.675
			c-0.152-0.424-0.005-0.897,0.358-1.164c0.975-0.712,2.169-1.563,3.499-2.462v2.784C29.759,44.348,29.387,45.087,29.387,45.087z
			 M33.498,42.533c-0.105,0.004-0.191,0.03-0.291,0.04V39.15c3.382-2.144,7.215-4.273,10.511-5.34c1.5-0.485,4.236-0.795,7.636-0.98
			v11.668l-14.412,0.589C36.942,45.087,36.442,42.423,33.498,42.533z M91.768,41.475c-0.503,0.874-1.419,1.429-2.426,1.471
			L77.49,43.43l-1.062-10.594c4.898,0.149,8.99,0.333,11.063,0.445c0.959,0.051,1.824,0.604,2.271,1.454l2.057,3.903
			C92.29,39.531,92.27,40.603,91.768,41.475z"
                              />
                            </svg>
                          </div>
                          <label htmlFor="drive" className="radio-tile-label">
                            INNOVA
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledWrapper2>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 0 3rem 0",
                }}
              >
                <p style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
                  Price: Rs {amount}/KM
                </p>
              </div>
              <form className="box-form">
                <div className="box-form__car-type">
                  {/* <label>
                    Name <b>*</b>
                  </label> */}
                  <TextField  label="Name" variant="filled" color="warning" value={name}
                    onChange={handleName}
                    type="text"
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.7rem', // Adjust the font size here
                        color: 'black',    // Set the font color to orange
                      },
                   
                    }}
                 />
                </div>
                <div className="box-form__car-type">
                  {/* <label>
                    {" "}
                    Mobile Number <b>*</b>
                  </label> */}
                     <TextField  label="Mobile" variant="filled" color="warning"  value={phone}
                    onChange={handlePhone}
                    type="tel"
                    inputProps={{
                      inputMode: 'numeric',  // Ensures numeric keyboard on mobile devices
                      pattern: '[0-9]*',     // Restricts input to numbers only
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.7rem', // Adjust the font size here
                        color: 'black',    // Set the font color to orange
                      },
                   
                    }}
                 />
          
                </div>
                <div className="box-form__car-type">
                  {/* <label>
                    {" "}
                    Email id <b>*</b>
                  </label> */}
                         <TextField  label="Mail" variant="filled" color="warning"  value={email}
                    onChange={handleEmail}
                    type="email"
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.7rem', // Adjust the font size here
                        color: 'black',    // Set the font color to orange
                      },
                   
                    }}
                 />
              
                </div>

                <div className="box-form__car-type">
                  {/* <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; Pick-up{" "}
                    <b>*</b>
                  </label> */}
              <Autocomplete
  apiKey={googleMapsApiKey}
  onPlaceSelected={(place) => setPickUp(place)}
  options={{ types: ["geocode"] }}
  placeholder="Pickup place."
  inputProps={{
    name: "pickUp",
    id: "pickUp",
    value: pickUp,
  }}
  style={{
    fontSize: "1.7rem",
    color: "#000", // Black text color for input
    backgroundColor: "#F5F5F5", // Light gray background to simulate the filled variant
    border: "none", // Remove border to match filled variant
    borderBottom: "2px solid #000", // Bottom border to mimic the filled variant
    borderRadius: "4px", // Slight border radius for smooth corners
    padding: "1.2rem 1.3rem", // Consistent padding
    outline: "none", // Remove the default outline
    width: "100%", // Full width to match the TextField's behavior
    boxShadow: "none", // Remove any box shadow
  }}
/>

                </div>

                <div className="box-form__car-type">
                  {/* <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; Drop-off{" "}
                    <b>*</b>
                  </label> */}
                  <Autocomplete
                    apiKey={googleMapsApiKey}
                    onPlaceSelected={(place) => setDropOff(place)}
                    options={{ types: ["geocode"] }}
                    placeholder="Dropoff place."
                    inputProps={{
                      name: "dropoff",
                      id: "dropoff",
                      value: dropOff,
                    }}
                    style={{
                      fontSize: "1.7rem",
                      color: "#000", // Black text color for input
                      backgroundColor: "#F5F5F5", // Light gray background to simulate the filled variant
                      border: "none", // Remove border to match filled variant
                      borderBottom: "2px solid #000", // Bottom border to mimic the filled variant
                      borderRadius: "4px", // Slight border radius for smooth corners
                      padding: "1.2rem 1.3rem", // Consistent padding
                      outline: "none", // Remove the default outline
                      width: "100%", // Full width to match the TextField's behavior
                      boxShadow: "none", // Remove any box shadow
                    }}
                  />
                </div>



                <div className="box-form__car-time">
                  {/* <label htmlFor="droptime">
                    <i className="fa-regular fa-calendar-days "></i> &nbsp; Date{" "}
                    <b>*</b>
                  </label> */}
                  {/* <input
                    id="droptime"
                    value={date}
                    onChange={handleDate}
                    type="date"
                  ></input> */}
   <LocalizationProvider dateAdapter={AdapterDayjs}>
   <MobileDateTimePicker
    label="Date and Time"
    format="DD MMM YYYY hh:mm A"
    onChange={handleDateTimeChange}
    slotProps={{
        textField: {
            variant: 'filled',
            color: 'warning',
            InputProps: {
                style: {
                    fontSize: '1.7rem', // Increase font size
                    color: 'black', // Set font color to orange
                },
            },
            InputLabelProps: {
                style: {
                    // fontSize: '1.4rem', // Adjust label font size if needed
                },
            },
        },
    }}
/>
  
   
    </LocalizationProvider>

                </div>
                {selectedValue === "round trip" &&   <div className="box-form__car-type">
  <TextField  label="No of Days" variant="filled" color="warning"  value={noOfDays}
                    onChange={handleDays}
                    type="number"
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.7rem', // Adjust the font size here
                        color: 'black',    // Set the font color to orange
                      },
                   
                    }}
                 />

                </div>}
                <button onClick={openModal} type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>Confirm Details</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        {/* <div className="booking-modal__message">
          <p>Confirm details to book taxi now</p>
        </div> */}

        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>
            {selectedValue} Price : Rs {amount}/KM
          </h4>
          <form className="info-form">

            <div style={cardImage}>
              {" "}
              <div className="card" style={cardStyle}>
                <img
                  src={image}
                  alt={selectedCarValue}
                  className="card-image"
                  style={imageStyle}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    padding: "1rem 1rem",
                    backgroundColor: "#ff4d30",
                    color: "white",
                    alignItems: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  <p>Estimate Price : Rs {formatPrice(estAmount)}</p>
                  <p className="card-text" style={textStyle}>Including driver betta</p>
                </div>
                <div className="card-body" style={bodyStyle}>
                  <h2 className="card-title" style={titleStyle}>
                    {selectedCarValue}
                  </h2>
                  <p className="card-text" style={textStyle}>
                    Estimate Distance : <span style={{color:"#ff4d30"}}>{distance} km</span>
                  </p>
                  <hr></hr>
                  <p className="card-text" style={textStyle}>
                    Minimum Distance For Round Trip : 250 KM
                  </p>
                  <p className="card-text" style={textStyle}>
                    Minimum Distance For One Way Trip : 130 KM
                  </p>
                  <p className="card-text" style={textStyle}>
                    Extras Toll, parking, hills charges Applicable
                  </p>
                </div>
              </div>
            </div>
            <div className="reserve-button">
              <button onClick={confirmBooking}>Book Now</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
const StyledWrapper = styled.div`
  .radio-input input {
    display: none;
  }
  .radio-div {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
  }
  .radio-input {
    --container_width: 300px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    color: #000000;
    width: var(--container_width);
    overflow: hidden;
    border: 1px solid rgba(53, 52, 52, 0.226);

    @media (max-width: 767px) {
      --container_width: 200px;
      font-size: 12px;
    }
  }

  .radio-input label {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;

    @media (max-width: 767px) {
      padding: 8px;
      font-size: 12px;
    }
  }

  .selection {
    display: none;
    position: absolute;
    height: 100%;
    width: calc(var(--container_width) / 2);
    z-index: 0;
    left: 0;
    top: 0;
    transition: 0.15s ease;
  }

  .radio-input label:has(input:checked) {
    color: #fff;
  }

  .radio-input label:has(input:checked) ~ .selection {
    background-color: #ff4d30;
    display: inline-block;
  }

  .radio-input label:nth-child(1):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 0 / 2));
  }

  .radio-input label:nth-child(2):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 1 / 2));
  }
`;

const StyledWrapper2 = styled.div`
  .car {
    display: flex;
    justify-content: center;
    margin: 0 0 0.5rem 0;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .radio-tile-group .input-container {
    position: relative;
    height: 80px;
    width: 80px;
    margin: 0.5rem;
  }

  .radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }

  .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #ff4d30;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
  }

  .radio-tile-group .input-container .icon svg {
    fill: #ff4d30;
    width: 5rem;
    height: 5rem;
  }

  .radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ff4d30;
  }

  .radio-tile-group .input-container .radio-button:checked + .radio-tile {
    background-color: #ff4d30;
    border: 2px solid #ff4d30;
    color: white;
    transform: scale(1.1, 1.1);
  }

  .radio-tile-group
    .input-container
    .radio-button:checked
    + .radio-tile
    .icon
    svg {
    fill: white;
    background-color: #ff4d30;
  }

  .radio-tile-group
    .input-container
    .radio-button:checked
    + .radio-tile
    .radio-tile-label {F
    color: white;
    background-color: #ff4d30;
  }
`;

export default BookCar;
